exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-upload-data-js": () => import("./../../../src/pages/upload/data.js" /* webpackChunkName: "component---src-pages-upload-data-js" */),
  "component---src-pages-upload-project-js": () => import("./../../../src/pages/upload/project.js" /* webpackChunkName: "component---src-pages-upload-project-js" */),
  "component---src-pages-upload-sensor-js": () => import("./../../../src/pages/upload/sensor.js" /* webpackChunkName: "component---src-pages-upload-sensor-js" */),
  "component---src-pages-workshops-index-js": () => import("./../../../src/pages/workshops/index.js" /* webpackChunkName: "component---src-pages-workshops-index-js" */),
  "component---src-pages-workshops-strapi-workshop-slug-index-js": () => import("./../../../src/pages/workshops/{strapiWorkshop.slug}/index.js" /* webpackChunkName: "component---src-pages-workshops-strapi-workshop-slug-index-js" */),
  "component---src-pages-workshops-strapi-workshop-slug-learn-js": () => import("./../../../src/pages/workshops/{strapiWorkshop.slug}/learn.js" /* webpackChunkName: "component---src-pages-workshops-strapi-workshop-slug-learn-js" */)
}

